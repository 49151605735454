<template>
     <section class="page_title big  pdt_80  pdb_40">
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-12 col-md-12 text-center">
                        <!---------col----------->
                        <div class="content_box">
                           <h6>Recent from the News</h6>
                           <h1>  Latest News and Updates</h1>
                           <ul class="bread_crumb text-center">
                              <li class="bread_crumb-item"><a href="#">Home</a></li>
                              <li class="bread_crumb-item"><a href="#">Media</a></li>
                              <li class="bread_crumb-item active">News</li>
                           </ul>
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
               </div>
            </section>
            <!-----------page_title-end-------------->
            <!-----------blog_v1--------------->
            <section class="blog_v1 pdt_80  pdb_100">
               <div class="container">
                <div class="featured_post">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-4">
                        <!---------blog_box----------->
                        <div class="blog_box type_one  fadeInUp">
                     <div class="image_box">
                        <img src="@/assets/image/news/blogpartnership.jpg" class="img-fluid" alt="img" />
                        <div class="overlay ">
                           <a href="@/assets/image/news/blogpartnership.jpg" data-fancybox="gallery " data-caption=" ">
                           <span class="linearicons-picture2"></span>
                           </a>
                        </div>
                     </div>
                     <div class="content_box">
                        <h2 ><router-link :to="{name:'maksphleadsapartnership'}">MakSPH leads a partnership of five African universities to
generate evidence to support the COVID-19 response</router-link></h2>
                        <ul>
                           <li><span class="linearicons-calendar-full"></span>17 April 2020</li>
                          <li><span class="linearicons-user"></span>Davidson Ndyabahika</li>
                        </ul>
                        <p>MakSPH is in the second year of the assessment of the COVID-19 response in Africa in partnership with several universities including University of Cheikh Anta Diop Dakar, Senegal; University of Ibadan, Nigeria; University of Kinshasa, DRC; and the University of Ghana.</p>
                        <router-link :to="{name:'maksphleadsapartnership'}" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></router-link>
                     </div>
                  </div>
                        <!---------blog_box end----------->
                     </div>


                     <div class="col-lg-4">
                        <!---------blog_box----------->
                    <div class="blog_box type_one  fadeInUp">
                     <div class="image_box">
                        <img src="@/assets/image/news/nitalauch.jpg" class="img-fluid" alt="img" />
                        <div class="overlay ">
                           <a href="@/assets/image/news/nitalauch.jpg" data-fancybox="gallery " data-caption=" ">
                           <span class="linearicons-picture2"></span>
                           </a>
                        </div>
                     </div>
                     <div class="content_box">
                        <h2 ><router-link :to="{name:'nitaulauch'}">MTN, NITA-U LAUNCH NEW APP FOR TRACKING COVID-19 PATIENTS UNDER HOME BASED CARE</router-link></h2>
                        <ul>
                           <li><span class="linearicons-calendar-full"></span>25 Mar 2020</li>
                           <li><span class="linearicons-user"></span>World Vision Ghana</li>
                        </ul>
                        <p>MTN Uganda in partnership with the National Information Technology Authority NITA-Uganda (NITA-U) on Friday March 19, 2021 unveiled a new app that will be used for geo-fencing and tracking COVID-19 patients that are under home based care.</p>
                        <router-link :to="{name:'nitaulauch'}" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></router-link>
                     </div>
                  </div>
                        <!---------blog_box end----------->
                     </div>


                     <div class="col-lg-4">
                     <div class="blog_box type_one  fadeInUp">
                     <div class="image_box">
                        <img src="@/assets/image/news/storyghana.jpg" class="img-fluid" alt="img" />
                        <div class="overlay ">
                           <a href="@/assets/image/news/storyghana.jpg" data-fancybox="gallery " data-caption=" ">
                           <span class="linearicons-picture2"></span>
                           </a>
                        </div>
                     </div>
                     <div class="content_box">
                        <h2 ><router-link :to="{name:'wvghanahelp'}">World Vision Supports Ghana Health Service in response to COVID-19</router-link></h2>
                        <ul>
                           <li><span class="linearicons-calendar-full"></span>20 Mar 2021</li>
                           <li><span class="linearicons-user"></span>Mulengera</li>
                        </ul>
                        <p>World Vision Ghana has provided about 40,000 Personal Protective Equipment such as nose masks, coveralls, goggles, gloves, disposable towels for hand drying, veronica buckets, antibacterial liquid hand wash, large plastic basins and foot operated plastic waste bins.</p>
                        <router-link :to="{name:'wvghanahelp'}" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></router-link>
                     </div>
                  </div>
                     
                     </div>
                     <div class="col-lg-4">
                     <div class="blog_box type_one  fadeInUp">
                     <div class="image_box">
                        <img src="@/assets/image/news/ghananewround.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                        <div class="overlay ">
                           <a href="@/assets/image/news/ghananewround.jpg" data-fancybox="gallery " data-caption=" ">
                           <span class="linearicons-picture2"></span>
                           </a>
                        </div>
                     </div>
                     <div class="content_box">
                        <h2 style="height:180px; overflow:hidden " ><a href="http://www.xinhuanet.com/english/2021-08/17/c_1310130940_2.htm" target="_blank">Ghana starts new round of COVID-19 vaccination</a></h2>
                        <ul>
                           <li><span class="linearicons-calendar-full"></span>25 April 2021</li>
                           <li><span class="linearicons-user"></span>Xinhua</li>
                        </ul>
                        <p>A nurse gives a man an injection of the COVID-19 vaccine at a vaccination site in Accra, Ghana, on Aug. 16, 2021. Ghana on Monday began a new round of COVID-19 vaccination with 177,700 single-dose Johnson & Johnson vaccines to be administered, said health authorities.</p>
                        <a href="http://www.xinhuanet.com/english/2021-08/17/c_1310130940_2.htm" target="_blank" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></a>
                     </div>
                  </div>
                     </div>


                     <div class="col-lg-4">
                     <div class="blog_box type_one  fadeInUp">
                     <div class="image_box">
                        <img src="@/assets/image/news/ghanatest.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                        <div class="overlay ">
                           <a href="@/assets/image/news/ghanatest.jpg" data-fancybox="gallery " data-caption=" ">
                           <span class="linearicons-picture2"></span>
                           </a>
                        </div>
                     </div>
                     <div class="content_box">
                        <h2 style="height:180px; overflow:hidden " ><a href="https://theconversation.com/covid-19-has-left-ghanas-healthcare-workers-stressed-but-simple-things-can-help-157907" target="_blank">COVID-19 has left Ghana’s healthcare workers stressed – but simple things can help</a></h2>
                        <ul>
                           <li><span class="linearicons-calendar-full"></span>30 April 2021</li>
                           <li><span class="linearicons-user"></span>Patience Afulani</li>
                        </ul>
                        <p>Stress and burnout among healthcare workers is a global crisis that predates the COVID-19 pandemic. Studies done before the pandemic showed that many healthcare workers around the world were experiencing high levels of stress and burnout. But the pandemic has exacerbated this – particularly in low resource settings.</p>
                        <a href="https://theconversation.com/covid-19-has-left-ghanas-healthcare-workers-stressed-but-simple-things-can-help-157907" target="_blank" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></a>
                     </div>
                  </div>
                     </div>



                     <div class="col-lg-4">


                        <div class="blog_box type_one  fadeInUp">
                     <div class="image_box">
                        <img src="@/assets/image/news/covidvac2.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                        <div class="overlay ">
                           <a href="@/assets/image/news/covidvac2.jpg" data-fancybox="gallery " data-caption=" ">
                           <span class="linearicons-picture2"></span>
                           </a>
                        </div>
                     </div>
                     <div class="content_box">
                        <h2 style="height:180px; overflow:hidden " ><a href="https://theconversation.com/ghanas-covid-19-vaccine-rollout-is-struggling-to-keep-up-with-its-great-start-159579" target="_blank">Ghana’s COVID-19 vaccine rollout is struggling to keep up with its great start</a></h2>
                        <ul>
                           <li><span class="linearicons-calendar-full"></span>1st May 2021</li>
                           <li><span class="linearicons-user"></span>Nana Kofi Quakyi</li>
                        </ul>
                        <p>Ghana became the first country globally to receive a vaccine shipment from the COVAX facility – a global initiative that’s trying to ensure equitable access to COVID-19 vaccines – when 600,000 Oxford-AstraZeneca vaccine doses were delivered in February 2021. </p>
                        <a href="https://theconversation.com/ghanas-covid-19-vaccine-rollout-is-struggling-to-keep-up-with-its-great-start-159579" target="_blank" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></a>
                     </div>
                  </div>
                     </div>



                     <div class="col-lg-4">


                        <div class="blog_box type_one  fadeInUp">
                     <div class="image_box">
                        <img src="@/assets/image/news/covidvaccine.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                        <div class="overlay ">
                           <a href="@/assets/image/news/covidvaccine.jpg" data-fancybox="gallery " data-caption=" ">
                           <span class="linearicons-picture2"></span>
                           </a>
                        </div>
                     </div>
                     <div class="content_box">
                        <h2 style="height:180px; overflow:hidden " ><a href="https://theconversation.com/ghanas-covid-19-vaccine-rollout-is-struggling-to-keep-up-with-its-great-start-159579" target="_blank">Ghana’s COVID-19 vaccine rollout is struggling to keep up with its great start</a></h2>
                        <ul>
                           <li><span class="linearicons-calendar-full"></span>1st May 2021</li>
                           <li><span class="linearicons-user"></span>Nana Kofi Quakyi</li>
                        </ul>
                        <p>Ghana became the first country globally to receive a vaccine shipment from the COVAX facility – a global initiative that’s trying to ensure equitable access to COVID-19 vaccines – when 600,000 Oxford-AstraZeneca vaccine doses were delivered in February 2021. </p>
                        <a href="https://theconversation.com/ghanas-covid-19-vaccine-rollout-is-struggling-to-keep-up-with-its-great-start-159579" target="_blank" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></a>
                     </div>
                  </div>
                     </div>



                     
                     <div class="col-lg-4">

                        <div class="blog_box type_one  fadeInUp">
                        <div class="image_box">
                           <img src="@/assets/image/news/1024x576_cmsv2_16f7ec04-f08b-5517-a241-7f578625bcdd-6593480.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                           <div class="overlay ">
                              <a href="@/assets/image/news/1024x576_cmsv2_16f7ec04-f08b-5517-a241-7f578625bcdd-6593480.jpg" data-fancybox="gallery " data-caption=" ">
                              <span class="linearicons-picture2"></span>
                              </a>
                           </div>
                        </div>
                        <div class="content_box">
                           <h2 style="height:180px; overflow:hidden " ><a href="https://www.africanews.com/2022/04/06/covid-19-limit-on-attendance-and-curfew-lifted-in-nigeria/" target="_blank">Covid-19: Limit on attendance and curfew lifted in Nigeria</a></h2>
                           <ul>
                              <li><span class="linearicons-calendar-full"></span>6th Feb 2022</li>
                              <li><span class="linearicons-user"></span>Nan</li>
                           </ul>
                           <p>The Nigerian government eased most of the remaining Covid rules which included a night time curfew and limitations on gatherings, imposed at the start of the outbreak in Africa's most populous country</p>
                           <a href="https://www.africanews.com/2022/04/06/covid-19-limit-on-attendance-and-curfew-lifted-in-nigeria/" target="_blank" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></a>
                        </div>
                     </div>
                     </div>

                     
                     <div class="col-lg-4">

                     <div class="blog_box type_one  fadeInUp">
                        <div class="image_box">
                           <img src="@/assets/image/news/fe1f9d55-9e8d-4843-affe-2d7a75396271_w1597_n_r0_st_s.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                           <div class="overlay ">
                              <a href="@/assets/image/news/fe1f9d55-9e8d-4843-affe-2d7a75396271_w1597_n_r0_st_s.jpg" data-fancybox="gallery " data-caption=" ">
                              <span class="linearicons-picture2"></span>
                              </a>
                           </div>
                        </div>
                        <div class="content_box">
                           <h2 style="height:180px; overflow:hidden " ><a href="https://www.voanews.com/a/covid-19-pandemic_congolese-refugees-look-inward-support-amid-covid-19-scourge/6207152.html" target="_blank">Congolese Refugees Look Inward for Support Amid COVID-19 Scourge</a></h2>
                           <ul>
                              <li><span class="linearicons-calendar-full"></span>6th Feb 2022</li>
                              <li><span class="linearicons-user"></span>Nan</li>
                           </ul>
                           <p>Uganda, Africa's largest refugee host, is imposing restrictions on movement after another spike in COVID-19 cases, and that’s creating an even greater economic struggle for the vulnerable in urban areas.</p>
                           <a href="https://www.voanews.com/a/covid-19-pandemic_congolese-refugees-look-inward-support-amid-covid-19-scourge/6207152.html" target="_blank" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></a>
                        </div>
                     </div>

                     </div>
                     <div class="col-lg-4">
                     </div>
                     <div class="col-lg-4">
                     </div>
                     <div class="col-lg-4">
                     </div>


                   
                     <!----------row-end---------->
                  </div>
                </div>
            
               </div>
            </section>
            <!-----------blog_v1-end-------------->
       
</template>